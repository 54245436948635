<template>
    <KTLoader v-if="true" />

    <section class="vh-100" style="background:white !important" v-if="this.session_id_search === ''">
        <div class="row">
            <div class="col-sm-5 px-0 d-none d-sm-block">
                <img src="/media/fps/bg1.svg" alt="Login image" class="w-100"
                    style="object-fit: cover; object-position: center;min-height:929px">
                <img src="/media/login/4.png" style="position: absolute; left: 32px; top: 38px;">

            </div>
            <div class="col-sm-7 text-black">

                <div class="d-flex flex-center flex-column flex-column-fluid"
                    style="object-position: center; min-height:929px; margin-top: -10%;">
                    <h1
                        style="font-weight: 500; font-size: 40px; line-height: 48px; letter-spacing: -0.4px; color: #000000;">
                        Masuk</h1>

                    <h4 class="fw-normal mt-4"
                        style="font-weight: 300; font-size: 16px; line-height: 28px; text-align: center; color: #646464;">
                        Masuk dengan akun Google untuk melanjutkan
                    </h4>

                    <!-- login user & pass -->

                    <div class="w-lg-500px p-10 p-lg-15 mx-auto">
                        <!-- start login sso -->
                        <div class="pt-4">
                            <button @click="onSubmitLoginSso()" :data-kt-indicator-sso="loadingSso ? 'on' : null"
                                id="btnSubmitLoginSso" class="btn btn-dark btn-lg"
                                style="width:100%; background: #4C8EFA; border-radius: 8px;" ref="tLoginSso"
                                type="submit">
                                <span class="indicator-label">
                                    <img src="/media/login/Google.svg" alt="Login image"> &nbsp;
                                    Masuk Dengan Google
                                </span>
                                <span class="indicator-progress">
                                    Please wait...
                                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                        <!-- end -->
                    </div>
                </div>

            </div>

        </div>
    </section>
</template>
<script>
import { Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { ErrorMessage, Field, Form as VeeForm } from "vee-validate";
import * as Yup from "yup";
import JwtService from "@/core/services/JwtService";
import JwtServiceOPEX from "@/core/services/JwtServiceOPEX";
// import JwtServiceHRIS from "@/core/services/JwtServiceHRIS";

import KTLoader from "@/components/Loader.vue";
import { Actions } from "@/store/enums/StoreEnums";

export default {
    components: {
        Field,
        VeeForm,
        ErrorMessage,
        KTLoader,
    },
    data() {
        const login = Yup.object().shape({
            username: Yup.string().required('Username harap diisi!').label("Username").nullable(),
            password: Yup.string().required('Password harap diisi!').label("Password").nullable(),
        });
        const store = useStore();
        return {
            togglePass: false,
            iconPass: "far fa-eye",
            store: store,
            loading: false,
            loadingSso: false,
            field: {
                username: "",
                password: ""
            },

            formSso: {
                access_token: "",
                session_id: ""
            },
            login: login,

            session_id_search: "",
        }
    },
    mounted() {
        if (this.$store.getters.isUserAuthenticated) {
            // this.$router.go(-1)
            this.$router.replace({ path: "/dashboard" })
        } else {
            window.localStorage.removeItem('user');
            window.localStorage.removeItem('config');
            window.localStorage.removeItem('username');
            window.localStorage.removeItem('listPageMenu');
        }
        if (this.$store.getters.isMaintananceWeb) {
            this.$router.push({ name: "error-mt" });
        }
        this.session_id_search = window.location.search;
        if (window.location.search != "") {
            this.$store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading")

            const params = new URLSearchParams(window.location.search);
            const q = params.get("session_id");

            this.formSso.session_id = q;
            this.formSso.access_token = localStorage.getItem('access_token');

            this.loginSsoValidate();
        }

    },
    methods: {

        onSubmitLoginSso() {
            this.loadingSso = true
            this.$refs.tLoginSso.setAttribute("data-kt-indicator-sso", "on");
            this.$refs.tLoginSso.disabled = true;

            this.$apiservice.post('/authentication/v1/login-sso').then(res => {

                localStorage.setItem('access_token', res.data.data.access_token)
                localStorage.setItem('sso_redirect_url', res.data.data.sso_redirect_url)

                window.open(res.data.data.sso_redirect_url, "_self");
            })

        },

        loginSsoValidate() {
            this.loadingSso = true

            const datas = {
                token: null
            }
            this.$apiservice.post('/authentication/v1/login-sso-validate', this.formSso).then(res => {
                datas.token = res.data.token
                JwtService.saveToken(datas.token);
                // this.$store.commit(Mutations.PAGE_MENU)
                // this.$apihris.post('/authentication/v1/get-token', { api_key: process.env.VUE_APP_API_KEY_HRIS }).then(reshris => {
                //     JwtServiceHRIS.saveToken(reshris.data.token)
                // }).finally(() => {
                    // this.$apiopex.post('/authentication/v1/get-token', { api_key: process.env.VUE_APP_API_KEY_OPEX }).then(resopex => {
                    //     JwtServiceOPEX.saveToken(resopex.data.token)
                        this.$store.commit(Mutations.SET_AUTH, datas)
                    // }).finally(() => {
                        setTimeout(() => {
                            this.$store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading")
                            this.loadingSso = false
                            // this.$refs.tLogin.removeAttribute("data-kt-indicator");
                            // this.$refs.tLogin.disabled = false;
                            this.$router.replace({ path: "/dashboard" })
                        }, 2000)
                    // })
                // })

            }).catch(err => {

                if (err.response != undefined) {
                    this.$router.push({ path: "/sign-in" })
                    this.session_id_search = "";
                    const error = err.response.data
                    Swal.fire({
                        html: error.message.replace('\n', '<br>'),
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Ok !",
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-danger",
                        }
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.field.username = ""
                            this.field.password = ""
                        }
                    });
                    document.getElementsByClassName('swal2-popup')[0].setAttribute("id", "ModalSwal");
                    document.getElementsByClassName('swal2-confirm')[0].setAttribute("id", "confirmSwal");

                    this.$store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading")
                    this.loadingSso = false
                } else {
                    this.$router.push({ path: "/sign-in" })
                    this.session_id_search = "";

                    this.$store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading")
                    this.loadingSso = false
                }
            })
        },
    }
}
</script>
<style>
html,
body {
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
}

.bg-image-vertical {
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: right center;
    background-size: auto 100%;
}

@media (min-width: 1025px) {
    .h-custom-2 {
        height: 100%;
    }
}
</style>
